// PricingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './PricingPage.css';

const PricingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="pricing-page">
      <Navbar />

      <div className="pricing-section">
        <h1 className="hero-title">Choose Your Plan</h1>
        <div className="pricing-plans">
          <div className="pricing-card" onClick={() => navigate('/signup')}>
            <h2>Basic Plan</h2>
            <p><strong>$15/month</strong></p>
            <p><strong>$135/year</strong> (10% discount!)</p>
            <p>Basic livestock tracking, mobile app access, and email support.</p>
          </div>
          <div className="pricing-card" onClick={() => navigate('/signup')}>
            <h2>Pro Plan</h2>
            <p><strong>$35/month</strong></p>
            <p><strong>$315/year</strong> (10% discount!)</p>
            <p>Advanced management, device integration, and priority support.</p>
          </div>
          <div className="pricing-card" onClick={() => navigate('/signup')}>
            <h2>Premium Plan</h2>
            <p><strong>$75/month</strong></p>
            <p><strong>$675/year</strong> (10% discount!)</p>
            <p>Full customization, advanced integration, and 24/7 premium support.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PricingPage;
