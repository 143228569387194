// Navbar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'; // Navbar-specific styles

const Navbar = ({ transparent = false, hideGetAppButton = false }) => {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleNavClick = (path) => {
    navigate(path);
    setMobileMenuOpen(false); // Close mobile menu on navigation
  };

  return (
    <nav className={`navbar ${transparent ? 'transparent' : ''}`}>
      <div className="nav-logo">
        <img
          src="../../assets/white_on_trans_icon.png"
          alt="Toro Logo"
          className="nav-logo-img"
          onClick={() => navigate('/')}
        />
      </div>

      {/* Desktop Navigation */}
      <div className="desktop-nav">
        <ul className="nav-links">
          {/*<li className="nav-item" onClick={() => handleNavClick('/product')}>
            Products
          </li>
          <li className="nav-item" onClick={() => handleNavClick('/pricing')}>
            Pricing
          </li>*/}
          <li className="nav-item" onClick={() => handleNavClick('/contact')}>
            Support
          </li>
        </ul>
        {!hideGetAppButton && (
          <div className="top-right-button">
            <button
              className="nav-button"
              onClick={() => handleNavClick('/get-app')}
            >
              Get the App
            </button>
          </div>
        )}
      </div>

      {/* Mobile Navigation Toggle */}
      <div
        className="mobile-nav-toggle"
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        <div className="hamburger">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="mobile-menu">
          <ul className="mobile-nav-links">
            {/*<li className="mobile-nav-item" onClick={() => handleNavClick('/product')}>
              Products
            </li>
            <li className="mobile-nav-item" onClick={() => handleNavClick('/pricing')}>
              Pricing
            </li>*/}
            <li className="mobile-nav-item" onClick={() => handleNavClick('/contact')}>
              Support
            </li>
          </ul>
          {!hideGetAppButton && (
            <div className="mobile-nav-buttons">
              <button
                className="nav-button"
                onClick={() => handleNavClick('/get-app')}
              >
                Get the App
              </button>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
