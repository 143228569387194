// ProductsPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './ProductsPage.css';

const ProductsPage = () => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/signup'); // Navigates to the sign-up page when a card is clicked
  };

  return (
    <div className="products-page">
      <Navbar />

      {/* Page Title */}
      <div className="page-title">
        <h1>Toro Products</h1>
        <p>Empowering Ranchers with Effortless Livestock Management</p>
      </div>

      {/* Tiered Plans */}
      <div className="pricing-section">
        <div className="pricing-plan" onClick={handleCardClick}>
          <h2>Basic Plan - $15/month</h2>
          <ul>
            <li>Basic livestock tracking (health records, basic inventory)</li>
            <li>Mobile app access</li>
            <li>Basic reporting</li>
            <li>Email support</li>
          </ul>
        </div>
        <div className="pricing-plan" onClick={handleCardClick}>
          <h2>Pro Plan - $35/month</h2>
          <ul>
            <li>Everything in Basic</li>
            <li>Advanced livestock management (breeding, weaning, detailed health records)</li>
            <li>Enhanced reporting and analytics</li>
            <li>Priority email and chat support</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProductsPage;
