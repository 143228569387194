import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './terms.css'; // Ensure the correct path for the CSS file

const TermsOfService = () => {
  return (
    <div className="terms-container">
      {/* Consistent Navigation Bar */}
      <Navbar />

      {/* Terms of Service Content */}
      <div className="terms-content">
        <h1>Terms of Service</h1>
        <p className="last-updated"><strong>Last Updated: 12/18/2024</strong></p>
        
        <section className="terms-section">
          <h2>1. Acceptance of Terms</h2>
          <p>
            By using the Toro Livestock Management app and website (collectively referred to as “Services”), you agree to these Terms. If you do not agree, please discontinue the use of our Services.
          </p>
        </section>

        <section className="terms-section">
          <h2>2. Use of Our Services</h2>
          <p>
            You agree to use our Services only for lawful purposes and in compliance with all applicable laws and regulations. You must not misuse our Services by introducing viruses, attempting to gain unauthorized access, or any other malicious activity.
          </p>
        </section>

        <section className="terms-section">
          <h2>3. User Accounts</h2>
          <p>
            To access certain features of our Services, you may need to create an account. You are responsible for safeguarding your account information and agree not to share your login details with others.
          </p>
        </section>

        <section className="terms-section">
          <h2>4. Subscription and Payment</h2>
          <p>
            Toro Livestock Management offers paid subscription plans. Payments are processed at the time of checkout, and you will be billed monthly. You agree to provide accurate and complete payment information.
          </p>
        </section>

        <section className="terms-section">
          <h2>5. Cancellation and Refunds</h2>
          <p>
            You may cancel your subscription at any time through your account settings. No refunds will be provided for the remaining period of your subscription.
          </p>
        </section>

        <section className="terms-section">
          <h2>6. Intellectual Property</h2>
          <p>
            All content, features, and functionality of the Services are owned by Taylor’d Software Solutions. You may not reproduce, distribute, or otherwise exploit our content without written permission.
          </p>
        </section>

        <section className="terms-section">
          <h2>7. Limitation of Liability</h2>
          <p>
            We do not guarantee the accuracy, completeness, or reliability of the information provided through our Services. Toro Livestock Management and its affiliates shall not be liable for any damages arising from the use of our Services.
          </p>
        </section>

        <section className="terms-section">
          <h2>8. Changes to Terms</h2>
          <p>
            We may update these Terms from time to time. We will notify users of significant changes, and continued use of our Services after such changes constitutes your acceptance of the new Terms.
          </p>
        </section>

        <section className="terms-section">
          <h2>9. Opt-In Terms for Text Messaging</h2>
          <p>
            By providing your phone number and opting in, you agree to receive text messages from Toro Livestock Management. These messages may include updates, promotional offers, and other relevant information. Message frequency may vary, and standard message and data rates may apply. You can opt-out at any time by replying "STOP" to any message.
          </p>
        </section>

        <section className="terms-section">
          <h2>10. Contact Us</h2>
          <p>
            For questions or concerns, please contact us at <strong>info@torolm.com</strong>.
          </p>
        </section>
      </div>

      {/* Consistent Footer */}
      <Footer />
    </div>
  );
};

export default TermsOfService;
