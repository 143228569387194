// Footer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css'; // Footer-specific styles

const Footer = ({ transparent = false }) => {
  const navigate = useNavigate();

  return (
    <footer className={`footer ${transparent ? 'transparent' : ''}`}>
      <p>&copy; 2024 Taylor'd Software Solutions | All rights reserved.</p>
      <ul className="footer-links">
        <li onClick={() => navigate('/privacy')}>Privacy Policy</li>
        <li onClick={() => navigate('/terms')}>Terms of Service</li>
      </ul>
    </footer>
  );
};

export default Footer;
