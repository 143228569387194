// GetAppPage.js
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './GetAppPage.css';

// Import the images
import appleStoreIcon from '../../assets/apple_store_icon.png';
import googlePlayIcon from '../../assets/google_play_icon.png';

const GetAppPage = () => {
  // Countdown State: days, hours, minutes, seconds
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // State to track if countdown is finished
  const [countdownFinished, setCountdownFinished] = useState(false);

  // Set target date/time to March 5, 2025 10:00:00 local time (months are zero-indexed)
  const targetDate = new Date(2025, 2, 5, 10, 0, 0).getTime();

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setCountdownFinished(true);
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className="getapp-page">
      {/* Pass props to render transparent backgrounds and hide the Get the App button */}
      <Navbar transparent={true} hideGetAppButton={true} />

      {/* Main Content */}
      <div className="content-container">
        <h1 className="page-title">Get the App</h1>
        {!countdownFinished && (
          <p className="release-info">
            Releasing on: March 5, 2025 at 10:00 AM
          </p>
        )}
        
        {!countdownFinished ? (
          <div className="countdown-timer">
            <span>{timeLeft.days} {timeLeft.days === 1 ? 'day' : 'days'}</span>
            <span>{timeLeft.hours} {timeLeft.hours === 1 ? 'hour' : 'hours'}</span>
            <span>{timeLeft.minutes} {timeLeft.minutes === 1 ? 'minute' : 'minutes'}</span>
            <span>{timeLeft.seconds} {timeLeft.seconds === 1 ? 'second' : 'seconds'}</span>
          </div>
        ) : (
          <div className="download-links">
            <a
              href="https://apps.apple.com/app/6723902761"
              className="download-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img 
                src={appleStoreIcon} 
                alt="App Store" 
                className="store-icon"
              />
              <span>Download on the App Store</span>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.braedentaylor.Toro"
              className="download-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img 
                src={googlePlayIcon} 
                alt="Google Play" 
                className="store-icon"
              />
              <span>Get it on Google Play</span>
            </a>
          </div>
        )}
      </div>

      <Footer transparent={true} />
    </div>
  );
};

export default GetAppPage;
