// contact.js
import React, { useState } from 'react';
import axios from 'axios';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Navbar from './Navbar';
import Footer from './Footer';
import './contact.css';

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      alert('reCAPTCHA not yet loaded');
      return;
    }

    // Execute reCAPTCHA and get token (if you want to send it along)
    const token = await executeRecaptcha('contact_form');

    try {
      // Send the form data (and token if needed) to your support endpoint
      const response = await axios.post('https://api.torolm.com/support', { ...formData, token });
      alert(response.data.message);
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      alert(error.response?.data?.error || 'Something went wrong, please try again.');
    }
  };

  return (
    <div className="contact-container">
      {/* Navigation Bar */}
      <Navbar />

      {/* Contact Form Section */}
      <div className="contact-content">
        <h1>Contact Us</h1>
        <p className="contact-description">
          Have a question or need assistance? Feel free to reach out to us using the form below.
        </p>
        <p className="support-phone">
          Need immediate assistance?<br />
          Call our support at <a href="tel:8554028676">855-402-8676 option 1</a>
          {/* 8676 stands for toro */}
        </p>

        <form className="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />

          <label htmlFor="email">Email:</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />

          <label htmlFor="message">Message:</label>
          <textarea 
            id="message" 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            rows="5" 
            required 
          />

          <button type="submit" className="contact-submit">Submit</button>
        </form>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

const Contact = () => (
  <GoogleReCaptchaProvider reCaptchaKey="6LfXVTkqAAAAAN-cWAwBkgDauHoHLKTMBgBD2hAK">
    <ContactForm />
  </GoogleReCaptchaProvider>
);

export default Contact;
