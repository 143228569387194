import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './ResourcesPage.css';

const ResourcesPage = () => {
  return (
    <div className="resources-container">
      {/* Navigation Bar */}
      <Navbar />

      {/* Page Content */}
      <div className="resources-content">
        <h1>Resources</h1>
        <p className="resources-intro">
          Explore our collection of guides, articles, and FAQs to help you maximize the use of Toro Livestock Management.
        </p>

        {/* Articles Section */}
        <section className="resources-section">
          <h2>Articles & Guides</h2>
          <div className="resource-card">
            <h3>Getting Started with Toro</h3>
            <p>Learn how to set up your account, manage your livestock, and track key metrics efficiently.</p>
            <button className="read-more">Read More</button>
          </div>
          <div className="resource-card">
            <h3>Best Practices for Herd Management</h3>
            <p>Discover the best techniques for tracking livestock health, breeding, and performance.</p>
            <button className="read-more">Read More</button>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="resources-section">
          <h2>Frequently Asked Questions</h2>
          <details className="faq-item">
            <summary>How do I add livestock to my account?</summary>
            <p>You can add livestock by navigating to the "Add Livestock" section and filling in the necessary details.</p>
          </details>
          <details className="faq-item">
            <summary>Is my data secure?</summary>
            <p>Yes, we use industry-standard security measures to keep your data protected.</p>
          </details>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ResourcesPage;
