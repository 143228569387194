import React, { useState } from 'react';
import Navbar from '../Website Home/Navbar'; // adjust path if needed
import Footer from '../Website Home/Footer'; // adjust path if needed
import './CtaPage.css';

const CtaPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.torolm.com/newsletter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        setMessage(data.error || 'Subscription failed. Please try again.');
      } else {
        setMessage(data.message);
        setSubscriptionComplete(true);
        setEmail('');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="cta-page">
      <Navbar />

      <div className="cta-content">
        <h1>Stay Updated!</h1>
        <p>
          Sign up for our newsletter to receive the latest updates and exclusive
          offers about our app.
        </p>
        {/* Conditionally render the form only if subscription is not complete */}
        {!subscriptionComplete && (
          <form className="cta-form" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Sign Up</button>
          </form>
        )}
        {/* Always show the message, with extra margin if form is hidden */}
        {message && <p className="cta-message">{message}</p>}
      </div>

      <Footer />
    </div>
  );
};

export default CtaPage;
