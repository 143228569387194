// App.js
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './pages/Website Home/LandingPage';
import ProductsPage from './pages/Website Home/ProductsPage';
import PricingPage from './pages/Website Home/PricingPage';
import GetAppPage from './pages/Website Home/GetAppPage';
import ResourcesPage from './pages/Website Home/ResourcesPage';
import CtaPage from './pages/Website Home/CtaPage';
import Privacy from './pages/Website Home/privacy';
import Terms from './pages/Website Home/terms';
import Contact from './pages/Website Home/contact';
import './App.css'; // Global styles

function App() {
  return (
    <Router>
      <Routes>
        {/* Allowed Website Home routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/product" element={<ProductsPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/get-app" element={<GetAppPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/cta" element={<CtaPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />

        {/* Catch-all route: any other path will redirect to the landing page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
