// LandingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import Navbar from './Navbar';
import Footer from './Footer';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      {/* Navigation Bar */}
      <Navbar />

      {/* Video Background */}
      <div className="video-container">
      <video autoPlay loop muted className="background-video">
  <source src="/assets/toro_background.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>
      </div>

      {/* Hero Content */}
      <div className="hero-content">
        <h1 className="hero-title">Toro Livestock Management</h1>
        <p className="hero-subtitle">Track, analyze, and optimize your ranch</p>
        <div className="cta-container">
          <button className="cta-button" onClick={() => navigate('/get-app')}>
            Get Started
          </button>
          <button className="cta-button cta-button-outline" onClick={() => navigate('/cta')}>
            Learn More
          </button>
        </div>
      </div>

      {/* USP Section */}
      <div className="usp-section">
        <div className="usp-item">
          <h2>Mission Statement</h2>
          <p>
            Toro is dedicated to revolutionizing livestock management through intuitive, technology-driven solutions that empower ranchers to track, analyze, and optimize their operations with ease.
          </p>
        </div>
        <div className="usp-item">
          <h2>Core Values</h2>
          <ul className="core-values-list">
            <li><strong>Innovation:</strong> We leverage cutting-edge technology to simplify livestock management.</li>
            <li><strong>Reliability:</strong> Our platform is built for accuracy and dependability.</li>
            <li><strong>User-Centricity:</strong> We prioritize a seamless and intuitive user experience.</li>
            <li><strong>Efficiency:</strong> Our tools help ranchers make informed decisions faster.</li>
          </ul>
        </div>
        <div className="usp-item">
          <h2>Data-Driven Insights</h2>
          <p>
            Our platform harnesses real-time analytics to deliver actionable insights, empowering you to optimize operations and make informed decisions with confidence.
          </p>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
